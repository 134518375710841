.card {
  height: 350px;
  overflow: hidden;
  cursor: pointer;
}

.imageWrapper {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 12px 0px 12px;

  > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.description {
  font-size: 13px;
}

.stock {
  margin-top: 12px;
}

.cartQuantityWrapper {
  margin: 5px 0;
}

.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
}
