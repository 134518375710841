.articleNumber {
  font-weight: bold;
}

.articleDescription {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
