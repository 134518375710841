.xHeadersWrapper {
  background-color: #fafafa;
  border-bottom: 1px solid #cacaca;
}

.xHeadersRow {
  display: inline-flex;
}

.xHeadersCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  box-shadow: inset -1px 0px 0px 0px #cacaca;
  position: relative;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.xHeadersCellSelected {
  background-color: lighten(#1890ff, 42%);
}
