.addressRadioGroup {
  display: block;
}

.addressRadio {
  display: block;
  border: 1px solid #ebedf0;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.addressRadioValue {
  display: inline-block;
  vertical-align: middle;
}
