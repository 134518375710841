.deliveryDateGroupHeader {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 6px;
  margin-bottom: 6px;
}

.deliveryDateCell {
  width: 50%;
  padding: 0 8px;
  display: inline-block;
}
