.exportButton {
  display: block;
}

.exportDate {
  font-style: italic;
  font-size: 0.9em;
  display: inline-block;
}

.editButton {
  margin-right: 5px;
}

.refreshButton {
  margin-right: 5px;
}

.branches {
  display: block;
}

.divider {
  margin: 12px 0;
}
