.content {
  padding: 24px;
  margin: 0 auto;
  background-color: #fff;
}

.placeOrderContainer {
  margin-top: 10px;
}

.formInputElement {
  width: 100%;
}

.vendorOptionContainer {
  margin-bottom: 20px;
}

.vendorOptionPickerWrapper {
  margin-top: 10px;
}

.addressTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.addressTitle {
  font-size: 14px;
  font-weight: bold;
}
