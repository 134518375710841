.welcomeContent {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.userContent {
  color: #fff;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcomeLabel {
  font-size: 20px;
}

.nameLabel {
  font-size: 32px;
  margin-top: 4px;
}

.infoBlock:not(:first-child) {
  margin-top: 16px;
}

.infoLabel {
  font-size: 14px;
  font-weight: bold;
  color: #c5c2c2;
  letter-spacing: 2px;
}

.infoContent {
  margin-top: 2px;
  font-size: 20px;
}
