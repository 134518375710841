@import '../../styles/variables.scss';

:global {
  @import '~react-table/react-table';
  .ReactTable {
    border: none;
  }

  .ReactTable .rt-thead .rt-th,
  .ReactTable .rt-tbody .rt-td {
    border-right: none;
  }

  .ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #fafafa;
  }

  .ReactTable .rt-thead .rt-th {
    padding: 12px 8px;
  }

  .ReactTable .rt-thead .rt-th > *:first-child {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ReactTable .rt-td {
    padding: 8px;
  }

  .ReactTable .rt-thead .rt-th.-sort-desc,
  .ReactTable .rt-thead .rt-td.-sort-desc,
  .ReactTable .rt-thead .rt-th.-sort-asc,
  .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;
  }

  .ReactTable .rt-table {
    overflow: visible;
  }

  .ReactTable .rt-tbody {
    overflow: visible;
  }

  .ReactTable .rt-tr-group {
    flex-grow: 0;
  }

  .ReactTable .rt-noData {
    display: none;
  }

  .ReactTable .rt-thead .rt-tr,
  .ReactTable .rt-tbody .rt-tr-group,
  .ReactTable .rt-tbody .rt-tr-group:last-child {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }
}

.headerSorter {
  margin-left: 5px;
  width: 14px;
  height: 17px;
  color: #bfbfbf;
}

.headerSorterIconUp,
.headerSorterIconDown {
  font-size: 12px;
  line-height: 4px;
  height: 4px;
  display: block;
}

.headerSorterIconDown {
  margin-top: 4px;
}

.headerSorterIconActive {
  color: $primary-color;
}

.headerContent {
  text-align: left;
  display: flex;
  align-items: center;
}

.rowSelectable {
  cursor: pointer;
}

.skeleton {
  margin-top: 20px;
}

.empty {
  margin: 20px;
}

.paginationWrapper {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.textAlignRight {
  text-align: right;
}
