.container {
  width: 100%;
  overflow-x: auto;
}

.descriptionList {
  width: 100%;
}

.descriptionList tr > td {
  padding-top: 4px;
  padding-bottom: 4px;
  vertical-align: top;
}

.itemTitle {
  font-weight: 600;
  white-space: nowrap;
  width: 1px; // Ugly hack to make the name column fit its content
}

.itemValue {
  padding-left: 8px;
}

.divider {
  margin: 10px 0;
}
