.overlay {
  position: absolute;
  z-index: 100;
  pointer-events: none;
  opacity: 0.2;
}

.overflowLeft {
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  box-shadow: inset 12px 0px 7px -7px #000000;
}

.overflowRight {
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  box-shadow: inset -12px 0px 7px -7px #000000;
}

.overflowTop {
  top: 0;
  right: 0;
  left: 0;
  height: 20px;
  box-shadow: inset 0px 12px 7px -7px #000000;
}

.overflowBottom {
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  box-shadow: inset 0px -12px 7px -7px #000000;
}
