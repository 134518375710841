.baseWrapper {
  position: relative;
  padding-right: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
  border: 1px solid #cacaca;
}

.rowsContainer {
  position: relative;
  display: flex;
  width: 100%;
}

.axisLabels {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset -1px -1px 0px 0px #cacaca;
  user-select: none;
  background-color: #fafafa;
  font-weight: bold;
}

.svgLine {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  line {
    stroke: #cacaca;
    stroke-width: 1;
  }
}

.xAxisLabel {
  position: absolute;
  top: 15%;
  right: 15%;
}

.yAxisLabel {
  position: absolute;
  bottom: 15%;
  left: 15%;
}
