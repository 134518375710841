.baseLayout {
  min-height: 100vh;
}

.card {
  cursor: pointer;
  margin-top: 10px;
}

.content {
  margin: 0 auto;
  margin-top: 20px;
  width: 380px;
  text-align: center;
}

.cardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.cardTitles {
  flex-grow: 1;
}

.cardTitle {
  font-size: 16px;
  font-weight: bold;
}

.cardSubTitle {
  font-size: 15px;
}

.iconContainer {
  flex-shrink: 1;
  margin: 0 10px;
}
