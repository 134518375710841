@import '../../../styles/variables.scss';

.wrapper {
  line-height: normal;
  height: 34px;
  overflow: visible;
}

.searchInputWrapper {
  display: flex;
  align-items: center;
  line-height: normal;
  font-size: 14px;
  width: 270px;
  color: rgba(255, 255, 255, 0.75);
  border: none;
  position: relative;

  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    content: '';
    pointer-events: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.65);
  }

  &:hover:after {
    border-color: rgba(255, 255, 255, 0.85);
  }
}

.searchInputWrapperActive {
  color: rgba(255, 255, 255, 1);

  &:after {
    border-color: rgba(255, 255, 255, 1);
  }

  &:hover:after {
    border-color: rgba(255, 255, 255, 1);
  }
}

.searchIcon {
  margin-right: 5px;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  height: 30px;
  color: inherit;

  &::placeholder {
    color: inherit;
    opacity: 0.85;
  }
}
