.baseLayout {
  min-height: 100vh;
}

.content {
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messageIcon {
  font-size: 100px;
  margin-bottom: 20px;
}
