.newsItem {
  padding: 10px 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.newsItem:first-child {
  border-top: 0;
  padding-top: 0;
}

.newsItem:last-child {
  padding-bottom: 0;
}

.newsItemTitle {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsItemDate {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
