.page {
  background: #fff;
  position: relative;
}

.sider {
  background-color: #fafafa;
  overflow: auto;
}

.content {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.contentHeader {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.contentHeaderTitle {
  font-size: 16px;
  font-weight: 500;
}

.contentHeaderExtra {
  margin-left: auto;
}

.contentSection {
  margin-bottom: 15px;
}
