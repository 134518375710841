@import '../../../styles/variables.scss';

.page {
  margin: 0 auto;
  margin-bottom: 20px;
}

.navigation {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.headerTitle {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.headerTitle > *:not(:first-child) {
  margin-left: 10px;
}

.contentBlock:not(:first-child) {
  margin-top: 15px;
}

.addressTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.addressTitle {
  font-size: 14px;
  font-weight: bold;
}

.messageAlert {
  margin-bottom: 15px;
}

.careLabelPoList {
  white-space: pre-wrap;
}
