.header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
}

.headerCell {
  font-weight: 600;
  padding: 8px 10px;
  user-select: none;
  white-space: nowrap;
}

.nestedHeaderCell {
  color: #7d7d7d;
  font-weight: 600;
  padding: 8px 5px;
  user-select: none;
}

.rowCell {
  padding: 10px;
  display: flex;
  align-items: center;
}

.nestedRowCell {
  padding: 5px;
  overflow: hidden;
}

.rowCellContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rowTitleCellContent {
  display: flex;
  align-items: flex-start;
}

.actionCell {
  width: 80px;
  > *:not(:first-child) {
    margin-left: 5px;
  }
}

.articleDetailsCell {
  flex-grow: 1;
  overflow: hidden;
}

.numberCell {
  width: 155px;
  justify-content: flex-end;
  text-align: right;
}

.row {
  cursor: pointer;
  line-height: normal;
}

.row:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}

.rowContent {
  display: flex;
}

.nestedRow {
  position: relative;
  margin-bottom: 10px;
}

.variantNameCell {
  flex-grow: 1;
  flex-basis: 0;
}

.variantNumberCell {
  width: 100px;
  text-align: right;
}

.nestedRowContent {
  padding: 5px 15px;
  background-color: #fafafa;
  margin-left: 55px;
}

.nestedRowContent {
  &::after {
    content: ' ';
    position: absolute;
    left: 24px;
    border-left: 2px solid #e8e8e8;
    height: 18px;
    top: 0;
    width: 1px;
  }

  &::before {
    content: ' ';
    position: absolute;
    left: 24px;
    border-top: 2px solid #e8e8e8;
    width: 22px;
    top: 18px;
    height: 1px;
  }
}

.articleDetails {
  flex-grow: 1;
  overflow: hidden;
}

.articleImage {
  margin-right: 15px;
  flex-shrink: 0;
  flex-grow: 0;
}

.articleNumber {
  font-weight: bold;
}

.articleDescription {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.articleDetail {
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 16px;
}

.summary > .adminCostDescription {
  padding-bottom: 1rem;
}

.summary > .adminCostDescription > p {
  margin-left: auto;
  width: fit-content;
  font-size: 11px;
  margin-bottom: 0;
  font-style: italic;
}

.summary > .adminCostDescription.reached {
  display: none;
}

.summary > .totalPrice {
  display: flex;
}

.totalPriceLabel {
  font-weight: 600;
  margin-right: 15px;
}
