.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 400px;
  background-image: url('../../../images/dashboard.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #14171c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerContent {
  max-width: 1200px;
  flex-grow: 1;
}

.pageContent {
  margin: 0 auto;
  margin-top: 400px;
}

.extraMargin {
  margin-bottom: 10px;
}

.widgetContainer > :not(:first-child) {
  margin-top: 15px;
}
