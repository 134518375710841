@import '../../../../styles/variables.scss';

.container {
  display: flex;
  min-width: 600px;
}

.imageWrapper {
  width: 250px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-bottom: 30px;
  position: relative;
}

.image {
  flex-shrink: 0;
  max-height: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.frozenAlert {
  margin-bottom: 10px;
}

.extraDescription {
  white-space: pre;
}

.zoomIcon {
  margin-top: auto;
  margin-left: -15px;
  font-size: 15px;
  pointer-events: none;
}
