@import '../../../styles/variables.scss';

.page {
  margin: 0 auto;
  margin-bottom: 20px;
}

.navigation {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.headerTitle {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.headerTitle > *:not(:first-child) {
  margin-left: 10px;
}

.contentBlock:not(:first-child) {
  margin-top: 15px;
}

.summary {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.totalPriceLabel {
  font-weight: 600;
  font-size: 16px;
}

.orderMessage {
  white-space: pre-wrap;
  word-break: break-all;
}

.careLabelPoList {
  white-space: pre-wrap;
}

.messageAlert {
  margin-bottom: 15px;
}
