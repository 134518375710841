.container {
  overflow: auto;
}

.filter {
  padding: 20px 15px;
  border-bottom: 1px solid #e8e8e8;
  &:last-child {
    border-bottom: none;
  }
}

.filterHeader {
  font-weight: 500;
  margin-bottom: 10px;
  line-height: normal;
}
