.inputRow {
  margin: 1rem 0 1rem 0;
}

.width100 {
  width: 100%;
  a {
    width: 100%;
  }
}
