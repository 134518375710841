.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.items {
  margin: 0 -24px;
  padding: 0 24px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
}
