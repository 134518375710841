.awaitingDeliveriesCount {
  font-size: 2em;
  line-height: 1.5em;
  margin-right: 5px;
  margin-bottom: 0px;
  display: inline-block;
}

.awaitingDeliveriesCountText {
  font-size: 0.9em;
  font-style: italic;
  display: inline-block;
}

.exportButton {
  display: block;
}

.editButton {
  margin-right: 5px;
}

.divider {
  margin: 12px 0;
}

.branches {
  display: block;
}
