@import '../../../styles/variables.scss';

.container {
  height: 100%;
  position: relative;
}

.tabBadge {
  margin-left: 5px;

  :global {
    .ant-badge-dot {
      background-color: $primary-color;
    }
  }
}
