.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.matrixTableWrapper {
  position: relative;
  flex-grow: 1;
}

.matrixOrderSummary {
  position: relative;
  margin-left: 20px;
  width: 450px;
  height: 100%;
}
