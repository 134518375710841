.widgetCard {
  margin-right: 10px;
  min-width: 250px;
  width: 250px;
}

.moreIcon {
  font-size: 125%;
}

.categoryCard {
  margin-bottom: 20px;
}

.divProvided {
  display: flex;
  overflow-x: scroll;
}
