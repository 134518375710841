.stockContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
}

.footer {
  display: flex;
  width: 100%;
  border-top: 1px solid #e8e8e8;
}

.headerCell,
.footerCell {
  font-weight: 600;
  padding: 8px 5px;
  background: #fafafa;
  user-select: none;
  flex-grow: 1;
  flex-basis: 0;
}

.titleCell {
  max-width: 150px;
  min-width: 150px;
}

.row {
  display: flex;
  cursor: pointer;
}

.row:hover {
  background: darken(#fff, 5%);
}

.row:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}

.rowCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-basis: 0;
  padding: 5px;
}

.numericCell {
  text-align: right;
}

.empty {
  margin: 20px 0;
}
