@import '../../styles/variables.scss';

.baseLayout {
  min-height: 100vh;
}

.header {
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 0;
}

.topMenuWrapper {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.navMenuWrapper {
  display: flex;
  align-items: center;
}

.navMenu {
  margin-left: 20px;
}

.topMenu {
  line-height: 50px;
}

.topMenuItem {
  padding: 0 15px;
}

.topMenuItem > a > * {
  line-height: normal;
}

.topRightMenuItem {
  padding: 0 12px;
}

.contentWrapper {
  margin-top: 50px;
  height: calc(100vh - 50px);
  overflow-y: auto;
  width: 100%;
  position: relative;
}

.content {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding-top: 15px;
}

.warningBox {
  margin-bottom: 10px;
}

.quickSearchMenuItem {
  display: flex;
  align-items: center;
}

.menuItem {
  display: flex;
  align-items: center;
}

.menuItem .menuItemIcon {
  margin: 0;
  font-size: 20px;
}

.menuItem > *:not(:first-child) {
  margin-left: 5px;
}

.notificationBadge {
  color: #fff;

  :global {
    .ant-badge-dot {
      background-color: $primary-color;
    }
  }
}

.userMenuItem {
  display: flex;
  align-items: center;
  height: 50px;
  line-height: normal;
}

.userMenuItemSection {
  display: block;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}

.userMenuItemSection:not(:first-child) {
  margin-left: 10px;
}

.userMenuItemValue {
  margin-left: 5px;
  font-weight: bold;
}

.userMenuItemCaret {
  margin: 0px;
  margin-left: 5px;
}

.userMenu {
  max-width: 300px;
}

.userDetails {
  padding: 5px 12px;
}

.localeMenuItem {
  padding: 5px 12px;
}

.endShopSessionButton {
  margin: 0 auto;
  margin-top: 5px;
  display: block;
}
