.apiError {
  margin-bottom: 5px;
}

.createNewWidget {
  margin-right: 5px;
}

.bottomBox {
  height: 75px;
}

.noCategories {
  margin-top: 50px;
  text-align: center;
}
