.yHeadersWrapper {
  background-color: #fafafa;
  border-right: 1px solid #cacaca;
}

.yHeadersColumn {
  display: block;
}

.yHeadersCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  box-shadow: inset 0px -1px 0px 0px #cacaca;
  position: relative;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.yHeadersCellSelected {
  background-color: lighten(#1890ff, 42%);
}
