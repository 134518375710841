@import '../../../../styles/variables.scss';

.menu {
  position: relative;
  width: 270px;
  max-height: 400px;
  min-height: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #fff;
}

.menuItems {
  overflow: auto;
  max-height: 400px;
}

.noMenuItemsLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.errorLabel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: rgba($danger-bg-color, 0.95);
  z-index: 10;
  font-weight: 500;
  height: 100%;
  width: 100%;
}

.entry {
  padding: 10px;
  border-top: 1px solid #e8e8e8;
  cursor: pointer;
  &:first-child {
    border-top: none;
  }
  display: flex;
  align-items: center;
  text-align: left;
}

.entryActive {
  background-color: #e8e8e8;
}

.entryDetails {
  flex-grow: 1;
  overflow: hidden;
}

.entryImage {
  margin-right: 15px;
  flex-shrink: 0;
  flex-grow: 0;
}

.entryLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadMoreEntry {
  display: block;
  text-align: center;
  color: $primary-color;
}
