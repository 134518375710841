.cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  user-select: none;
  padding-left: 10px;
  padding-right: 10px;
}

.disabledCell {
  height: 100%;
  width: 100%;
  align-items: center;
  opacity: 0.6;
}

.cellInput {
  width: 100%;
  margin-top: 4px;
}

.cellItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2px;
  font-size: 12px;
}

.cellItemIcon {
  font-size: 14px;
  color: #848484;
}

.cellItemLabel {
  margin-left: 5px;
}

.quantityError {
  border-color: red !important;
}
