.container {
  padding: 8px;
  text-align: left;
  border: 1px solid #d9d9d9;
}

.totalSummary {
  display: flex;
}

.totalEntry {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 12px;
}

.totalEntryLabel {
  margin-bottom: 5px;
  font-weight: 600;
}

.totalEntryValue {
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-size: 16px;
}

.quantityError input:first-child {
  border-color: red !important;
}
