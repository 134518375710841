.cells {
  flex-grow: 1;
}

// IE 11 needs to wrap the cell rows in an inline-block
// in order to take up the full width
.cellsRowWrapper {
  display: inline-flex;
  flex-direction: column;
}

.cellsRow {
  position: relative;
  display: inline-flex;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    box-shadow: inset 0px -1px 0px 0px #cacaca;
    pointer-events: none;
  }
}
