.cell {
  flex-shrink: 0;
  flex-grow: 0;
  box-shadow: inset -1px 0px 0px 0px #cacaca;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #f9f9f9;
  }
}

.cellSelected {
  box-shadow: inset 0px 0px 0px 2px lighten(#1890ff, 25%);
}
