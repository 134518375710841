@import '../../../../styles/variables.scss';

.listItem {
  padding: 0;
}

.sortBySelect {
  width: 180px;
}

.actionsCell {
  display: flex;
  align-items: center;
  height: 100%;

  > *:not(:first-child) {
    margin-left: 5px;
  }
}

.paginationWrapper {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
