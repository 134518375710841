.modal {
  height: 100% !important;
  top: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.page {
  background: #fff;
  padding: 0 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 1000px;
  overflow-y: auto;
  max-height: 90vh;
}

.topContent {
  min-height: 250px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
}

.bottomContent {
  display: flex;
  flex-grow: 1;
}

.bottomStockWrapper {
  flex-grow: 1;
}

.blockDivider {
  margin-top: 0 !important;
}

.articleOrderWrapper {
  flex-shrink: 0;
  margin-left: 30px;
  width: 400px;
}

.pageMatrixArticle {
  height: 90vh;
  width: 90vw;
  max-width: 1500px;
  overflow: hidden;
}

.topStockWrapper {
  margin: 0 20px;
  flex-grow: 1;
}

.variantOrderWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.minimizeOrderSummaryButton {
  float: left;
  margin-bottom: 10px;
}

.minimizeTopContentButton {
  float: left;
  margin-bottom: 10px;
}

.minimizeFieldsWrapper {
  margin-right: 25px;
}
