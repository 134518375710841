.overlayClass {
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinnerClass {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
