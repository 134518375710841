.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.rows {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.actionsCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.updateCartContainer {
  padding: 8px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
}

.totalSummary {
  display: flex;
  margin-bottom: 2px;
}

.totalEntry {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 12px;
}

.totalEntryLabel {
  margin-bottom: 5px;
  font-weight: 600;
}

.totalEntryValue {
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-size: 16px;
}

.updateCartButton {
  display: block;
}
